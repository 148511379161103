import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {WindowSizePXEnum} from "../../interface/window";
import {scrollTo} from "../../utils/scrollTo";
import {ScrollToEnum} from "../../enum";
import {Shield} from "../../icons/icons";
import {Text18} from "../../styles/fonts";

export const Footer = () => {

    const [toTopVisible, setToTopVisible] = useState(false)
    function setScroll() {
        window.scrollY > 500 ? setToTopVisible(true) : setToTopVisible(false)
    }
    useEffect(() => {
        document.addEventListener("scroll", setScroll);

        return () => document.removeEventListener("scroll", setScroll);
    }, []);

    return (
        <FooterContainer>
            <FooterText>
                {`© 2015–${new Date().getFullYear()} DECK.AUTH`}
            </FooterText>
            <ToTop
                onClick={() => scrollTo(ScrollToEnum.intro)}
                style={toTopVisible ? {visibility: 'visible'} : {}}>
                <Shield/><Text18>Наверх</Text18> </ToTop>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.primary};
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    justify-content: start;
    padding: 30px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 30px 16px;
  }
`
const FooterText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  width: 1400px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: unset;
  }
`

export const ToTop = styled.div`
  visibility: hidden;
  position: fixed;
  z-index: 5;
  cursor: pointer;
  right: 50px;
  bottom: 100px;
  padding: 15px 20px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(255, 255, 255, .5);
  backdrop-filter: blur(5px) opacity(.8);
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    max-height: 30px;
    max-width: 30px;
    min-height: 30px;
    min-height: 30px;
  }

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  span {
    font-family: "SymbolaRegular", sans-serif;
    font-size: 24px;
    margin-right: 3px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
