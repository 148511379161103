import styled from "styled-components";
import {FlexRow} from "../../styles/content";
import {WindowSizePXEnum} from "../../interface/window";
import {Text16, TextLink, TextLinkDiv} from "../../styles/fonts";

export const ResourcesContainer = styled.div`
  padding-top: 80px;
  display: flex;
  justify-content: center;
  padding-bottom: 83px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    justify-content: flex-start;
    padding: 75px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 40px 16px;
  }

`
export const ResourcesContent = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: unset;
  }
`

export const ResourcesRow = styled(FlexRow)`
  padding-top: 56px;
  gap: 50px;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    padding-top: 38px;
    flex-direction: column;
    gap: 30px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding-top: 24px;
  }
`

export const ResourceCard = styled.div`
  width: 100%;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    //width: 517px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;

  }
`

export const ResourceText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;

  ${TextLink} {
    margin-top: 12px;
  }

  ${TextLinkDiv} {
    margin-top: 12px;
  }

  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    height: fit-content;
    ${TextLink} {
      margin-top: 4px;
    }

    ${Text16} {
      max-width: 500px;

    }
  }

`
export const ResourceTextMenu = styled.div`
  display: flex;
  position: absolute;
  top: 40px;
  flex-direction: column;
  transition: all .5s ease;
  -webkit-box-shadow: 0 0 29px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 0 29px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 0 0 29px 0 rgba(34, 60, 80, 0.2);
`
export const ResourceTextMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  background: rgb(255, 255, 255);

  :not(:last-child) {
    border-bottom: 1px solid rgba(34, 60, 80, 0.2);
  }

  :hover {
    filter: brightness(.95);
  }

  :active {
    filter: brightness(.9);
  }
`