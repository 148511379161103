import styled from "styled-components";
import {FlexRow} from "../../styles/content";
import {WindowSizePXEnum} from "../../interface/window";
import {Title48lh58} from "../../styles/fonts";

export const PartnersContainer = styled.div`
  padding-top: 80px;
  display: flex;
  justify-content: center;
  padding-bottom: 83px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    justify-content: flex-start;
    padding: 75px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 50px 16px;
  }

`
export const PartnersContainer2 = styled(PartnersContainer)`
  background: ${({theme}) => theme.colors.lightGrey};
  padding-bottom: 180px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    justify-content: flex-start;
    padding: 48px 32px;
    padding-bottom: 180px;
  }
`

export const PartnersContent = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
`

export const PartnersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    flex-direction: column;
    gap: 32px
  }
`


export const PartnersRow = styled(FlexRow)`
  padding-top: 56px;
  gap: 40px;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding-top: 42px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {

    gap: 20px;
    flex-direction: column;
  }
`

export const PartnersCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 30%;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    width:100%;
  }
`

export const PartnersCardMobileEmpty = styled(PartnersCard)`
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    display: none;
  }

`

export const PartnersText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    min-height: 300px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    min-height: 250px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {

    ${Title48lh58} {
      font-size: 35px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 100%;
    min-height: 100px;
    gap: 8px;
    ${Title48lh58} {
      font-size: 24px;
      line-height: 34px;
    }

  }
`

export const PartnersImage = styled.img`
  max-width: 50%;
  object-fit: contain;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    max-width: 100%;
  }
`
