import styled from "styled-components";

export const Input = styled.input`
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  z-index: 2;
  background: transparent;
  height: 40px;
  color: ${({theme}) => theme.colors.secondary};
  caret-color: #1192E8;

  :focus {
    border-bottom: 1px solid #1192E8;
  }

  :focus + label {
    color: ${({theme}) => theme.colors.secondary};
    transform: translateY(-20px);
  }
`

export const Label = styled.label`
  user-select: none;
  position: absolute;
  display: flex;
  color: #787878;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
  transition: 0.5s;
  top: -4px;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const InputContent = styled.div`
  height: 80px;
  position: relative;
  span {
    font-size: 11px;
    line-height: 13px;
    color: #ff4828;
    display: none;
    position: absolute;
    right: 0;
    top: 45px;
  }
  .numberInput {
    border: none;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    outline: none;
    z-index: 2;
    background: transparent;
    height: 40px;
    color: ${({theme}) => theme.colors.secondary};
    caret-color: #1192E8;

    :focus {
      border-bottom: 1px solid #1192E8;
    }

    :focus + label {
      color: ${({theme}) => theme.colors.secondary};
      transform: translateY(-20px);
    }
  }
`