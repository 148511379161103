import React from 'react';
import {Intro} from "./intro/Intro";
import {Benefits} from "./benefits/Benefits";
import {Architecture} from "./architecture/Architecture";
import {Resources} from "./resources/Resources";
import {Cooperation} from "./cooperation/Cooperation";

export const MainPage = () => {

    return (<>
            <Intro/>
            <Benefits/>
            <Architecture/>
            <Resources/>
            <Cooperation/>
        </>
    )
}