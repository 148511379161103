import styled from "styled-components";
import {FlexRow} from "../../styles/content";
import {WindowSizePXEnum} from "../../interface/window";

export const CooperationContainer = styled.div`
  padding: 70px 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.primary};
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 78px 32px 40px;
    justify-content: start;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 40px 16px;
  }
`
export const CooperationContent = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: 680px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 100%;
  }
`

export const FormContent = styled.div`
  width: 680px;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: 95%;
  }

`

export const FormContainer = styled.div`
`
export const CooperationRow = styled(FlexRow)`
  margin-top: 52px;
  gap: 100px;
  width: 100%;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    margin-top: 36px;
    flex-direction: column;
    gap: 70px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    margin-top: 28px;
    gap: 64px;
  }
`

export const CooperationModal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: #1A1A1E;
  justify-content: center;
  overflow: overlay;
  .cooperation {
    height: fit-content;
  }
`

export const ModalClose = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  right: 100px;
  cursor: pointer;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    top: 50px;
    right: 50px;
  }
`

export const Close = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28 6.4L6.61493 28L4.23881 25.6L25.6239 4L28 6.4Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 6.4L25.3851 28L27.7612 25.6L6.37612 4L4 6.4Z" fill="white"/>
</svg>
