import React, {useEffect, useRef, useState} from 'react'
import {Text18lh28, Title24Bold} from "../../styles/fonts";
import {Theme} from "../../styles/Theme";
import {FormContainer, FormContent} from './style';
import {DefaultBtn, DisabledBtn} from "../../styles/button";
import useOnScreen from "../../hooks/useOnScreen";
import {sendForm} from "../../utils/sendForm";
import {Popup, PopupBody, PopupClose, PopupContent, PopupHeader, PopupSubtitle, PopupTitle} from "../../styles/Popup";
import {useOnClickOutside} from "../../hooks/useClickOutside";
import {Close} from "../../icons/icons";
import {Input, InputContent, Label} from "../../styles/input";
import {PatternFormat} from "react-number-format";

const labelStyle = {
    transform: 'translateY(-20px)',
    color: Theme.colors.secondary
}

export const Form = () => {
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    const [emailErrorVisible, setEmailErrorVisible] = useState(false)
    const [nameErrorVisible, setNameErrorVisible] = useState(false)
    const [phoneErrorVisible, setPhoneErrorVisible] = useState(false)
    const [popupText, setPopupText] = useState('')
    const [popupOpened, setPopupOpened] = useState(false)
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)

    const popupRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(popupRef, () => setPopupOpened(false));

    useEffect(() => {
        setIsVisible(visible)
    }, [visible])


    return (
        <FormContent ref={ref} className={isVisible ? 'animation2' : ''} style={{opacity: `${isVisible ? 1 : 0}`}}>
            <Title24Bold color={Theme.colors.secondary}>Обратный звонок</Title24Bold>
            <FormContainer className={isVisible ? 'animation3 animation4' : ''}
                           style={{opacity: `${isVisible ? 1 : 0}`}}>

                <InputContent>
                    <Input className={nameErrorVisible && nameError ? 'underline-error' : 'underline'}
                           onFocus={() => setNameErrorVisible(false)}
                           onBlur={() => setNameErrorVisible(true)}
                           onChange={e => {
                               setName(e.currentTarget.value)
                               !e.currentTarget.validity.valid
                                   ? e.currentTarget.validationMessage.includes('указанном')
                                       ? setNameError('Поле может содержать только буквы.')
                                       : e.currentTarget.validationMessage.includes('символ')
                                           ? setNameError('Введите минимум 2 символа.')
                                           : setNameError(e.currentTarget.validationMessage)
                                   : setNameError('')
                           }}
                           required
                           pattern={'[a-zA-Z\u0400-\u04ff-\\s]+'}
                           maxLength={150}
                           minLength={2}
                           value={name}
                           id={'name'}/>
                    <Label
                        style={name.length > 0 ? labelStyle : {}}
                        htmlFor="name">
                        Ваше имя *
                    </Label>
                    <span style={nameErrorVisible ? {display: "block"} : {}}>
                        {nameError}
                    </span>
                </InputContent>

                <InputContent>
                    <PatternFormat
                        className={`${phoneErrorVisible && phoneError ? 'underline-error' : 'underline'} numberInput`}
                        id={'phone'}
                        type={'tel'}
                        format={'+#(###)###-##-##'}
                        mask={'_'}
                        onFocus={() => setPhoneErrorVisible(false)}
                        onBlur={() => setPhoneErrorVisible(true)}
                        onValueChange={(value) => {
                            let inputValue
                            if (value.value.substring(0, 1) === '8') {
                                inputValue = '+7' + value.formattedValue.substring(2, value.formattedValue.length)
                            } else inputValue = value.formattedValue
                            if (inputValue.includes('_')) {
                                setPhoneError('Введите корректный номер телефона.')
                            } else {
                                setPhoneError('')
                            }
                            if (inputValue.length === 0) {
                                setPhoneError('Введите номер телефона.')
                            }
                            setPhone(inputValue)
                        }}
                        value={phone}
                        title={'Введите номер телефона'}
                        required/>
                    <Label
                        style={phone.length > 0 ? labelStyle : {}}
                        htmlFor="phone">
                        Телефон *
                    </Label>
                    <span style={phoneErrorVisible ? {display: "block"} : {}}>
                        {phoneError}
                    </span>
                </InputContent>

                <InputContent>
                    <Input className={'underline'}
                           onChange={e => setCompany(e.currentTarget.value)}
                           value={company}
                           maxLength={150}
                           title={'Введите название вашей компании'}
                           id={'company'}/>
                    <Label
                        style={company.length > 0 ? labelStyle : {}}
                        htmlFor="company">
                        Название компании *
                    </Label>
                </InputContent>

                <InputContent>
                    <Input className={emailErrorVisible && emailError ? 'underline-error' : 'underline'}
                           onFocus={() => setEmailErrorVisible(false)}
                           onBlur={() => setEmailErrorVisible(true)}
                           onChange={e => {
                               setEmail(e.currentTarget.value)
                               !e.currentTarget.validity.valid
                                   ? setEmailError(`Введите корректный e-mail.`)
                                   : setEmailError('')
                           }}
                           value={email}
                           required
                           maxLength={100}
                           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                           id={'email'}
                           type={'email'}/>
                    <Label
                        style={email.length > 0 ? labelStyle : {}}
                        htmlFor="email">
                        Электронная почта *
                    </Label>
                    <span style={emailErrorVisible ? {display: "block"} : {}}>{emailError}</span>
                </InputContent>

                <InputContent>
                    <Input className={'underline'}
                           onChange={e => setMessage(e.currentTarget.value)}
                           value={message}
                           maxLength={150}
                           title={'Введите ваше сообщение'}
                           id={'message'}/>
                    <Label
                        style={message.length > 0 ? labelStyle : {}}
                        htmlFor="message">
                        Сообщение
                    </Label>
                </InputContent>

                <DefaultBtn
                    as={
                        nameError
                        || emailError
                        || phoneError
                        || name === ''
                        || email === ''
                        || phone === ''
                        || loading
                            ? DisabledBtn
                            : DefaultBtn}
                    style={{backgroundColor: Theme.colors.indicator}}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault()
                        setLoading(true)
                        sendForm({
                            form: {
                                name: name,
                                phone: phone,
                                company: company,
                                email: email,
                                message: message,
                            }
                        }).then((res) => {
                            if (res.ok) {
                                setPopupText('Ваша заявка отправлена, мы свяжемся с вами')
                                setPopupOpened(true)

                                setCompany('')
                                setName('')
                                setPhone('')
                                setEmail('')
                                setMessage('')
                                setEmailErrorVisible(false)
                                setNameErrorVisible(false)
                                setPhoneErrorVisible(false)
                            }
                        }).catch(() => {
                            setPopupText('Произошла ошибка при отправке, попробуйте еще раз.')
                        }).finally(() => setLoading(false))
                    }}>
                    <Text18lh28
                        color={Theme.colors.secondary}>
                        {loading ? 'Отправка...' : 'Подтвердить'}
                    </Text18lh28>
                </DefaultBtn>
            </FormContainer>

            <Popup className={popupOpened ? 'visible' : 'hidden'}>
                <PopupContent ref={popupRef}>
                    <PopupHeader>
                        <PopupTitle>{popupText ? popupText.toLowerCase().includes('ошибка') ? 'Ошибка' : 'Спасибо!' : ''}</PopupTitle>
                        <PopupClose>
                            <Close onClick={() => {
                                setPopupOpened(false)
                                setPopupText && setPopupText('')
                            }}/>
                        </PopupClose>
                    </PopupHeader>
                    <PopupBody>
                        <PopupSubtitle>
                            {popupOpened && popupText}
                        </PopupSubtitle>
                    </PopupBody>
                </PopupContent>
            </Popup>
        </FormContent>
    )
}