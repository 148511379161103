import React, {useEffect, useRef, useState} from 'react';
import {ResourceCard, ResourcesContainer, ResourcesContent, ResourcesRow, ResourceText} from "./style";
import {Text16, TextLink, Title24, Title48lh67} from "../../styles/fonts";
import useOnScreen from "../../hooks/useOnScreen";

export const Resources = () => {
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        setIsVisible(visible)
    }, [visible])

    return (
        <div ref={ref}>
            <ResourcesContainer className={'resources'}>
                <ResourcesContent>
                    <Title48lh67 className={isVisible ? 'animation5' : ''}
                                 style={{opacity: `${isVisible ? 1 : 0}`}}>Ресурсы</Title48lh67>
                    <ResourcesRow>
                        <ResourceCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Краткая информация о продукте</Title24>
                                <Text16>Информация о продукте
                                    на одной странице (executive&nbsp;summary)</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"} href={'/files/DECK AUTH executive summary.pdf'}>Скачать
                                    файл</TextLink>
                            </ResourceText>
                        </ResourceCard>

                        <ResourceCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Техническая спецификация</Title24>
                                <Text16>Детальная информация
                                    о продукте (data&nbsp;sheet)</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"} href={'/files/DECK AUTH data sheet.pdf'}>
                                    Скачать файл</TextLink>
                            </ResourceText>
                        </ResourceCard>

                        <ResourceCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Правила оказания сервиса</Title24>
                                <Text16>Информация о режимах и условиях оказания услуг по технической поддержке
                                    (service&nbsp;levels)</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"} href={'/files/DECK AUTH service levels.pdf'}>
                                    Скачать файл</TextLink>
                            </ResourceText>
                        </ResourceCard>
                    </ResourcesRow>
                </ResourcesContent>
            </ResourcesContainer>
        </div>
    )
}