import React, {useEffect, useRef, useState} from 'react'
import {Text18lh28, Title24Bold} from "../../styles/fonts";
import {ContentTop24, ContentTop4, FlexColumn} from "../../styles/content";
import {Theme} from "../../styles/Theme";
import useOnScreen from "../../hooks/useOnScreen";

export const Contacts = () => {
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        setIsVisible(visible)
    }, [visible])

    return (
        <FlexColumn ref={ref}>
            <div className={isVisible ? 'animation2' : ''} style={{opacity: `${isVisible ? 1 : 0}`}}>
                <Title24Bold color={Theme.colors.secondary}>Контакты</Title24Bold>
                <Text18lh28 color={Theme.colors.secondary}>Электронная почта</Text18lh28>
                <ContentTop4>
                    <Text18lh28 color={Theme.colors.indicator} pointer={true}>auth@deck.lc</Text18lh28>
                </ContentTop4>
                <ContentTop24>
                    <Text18lh28 color={Theme.colors.secondary}>Телефон</Text18lh28>
                    <ContentTop4>
                        <Text18lh28 color={Theme.colors.indicator} pointer={true}>+7 (843) 248-55-54</Text18lh28>
                    </ContentTop4>
                </ContentTop24>
            </div>
        </FlexColumn>
    )
}