import React, {useEffect, useRef, useState} from 'react'
import {BenefitCardContainer, CardText} from "./style";
import {Text18lh28, Title24} from "../../styles/fonts";
import useOnScreen from "../../hooks/useOnScreen";

interface BenefitCardProps {
    title: string,
    description: string
    icon: React.ReactElement
    invisible?: boolean
}

export const BenefitCard = ({title, description, icon, invisible}: BenefitCardProps) => {
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        setIsVisible(visible)
    }, [visible])

    return (
        <BenefitCardContainer ref={ref}
                              className={`${isVisible && 'animation2 animation5'} ${invisible && 'invisible'}`}
                              style={{opacity: `${isVisible ? 1 : 0}`}}
        >
            {icon}
            <CardText>
                <Title24>
                    {title}
                </Title24>
                <Text18lh28>
                    {description}
                </Text18lh28>
            </CardText>
        </BenefitCardContainer>
    )
}