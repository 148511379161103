import styled from "styled-components";
import {FlexColumn} from "../../styles/content";
import {WindowSizePXEnum} from "../../interface/window";

export const BenefitsContainer = styled.div`
  padding: 126px 0 80px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #E1E1E1;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 80px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    padding: 48px 16px;
  }
`
export const BenefitsContent = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: 100%;
  }
`
export const BenefitsTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 700px;
  align-items: center;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;
  }
`
export const BenefitCardContainer = styled.div`
  width: 435px;
  height: 330px;
  display: flex;
  flex-direction: column;
  padding: 37px 24px;
  gap: 16px;
  svg {
    min-height: 72px;
    min-width: 72px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 0;
    width: 390px;
    height: 294px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    padding: 0;
    width: 392px;
    height: 294px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;
    height: unset;
  }
`

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const BenefitsCards = styled.div`
  width: 1400px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    gap: 30px;
  }

`

export const BenefitsColumn = styled(FlexColumn)`
  gap: 48px;
  align-items: center;
`
