export enum TabEnum {
    main = 1,
    partners = 2,
}

export enum ScrollToEnum {
    intro = '.intro',
    partners = '.partners',
    cooperation = '.cooperation',
}
