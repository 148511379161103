import React from 'react'
import {Close, Logo, Menu} from "../../icons/icons";
import {Text18} from "../../styles/fonts";
import {useTheme} from "styled-components";
import {DefaultBtn} from "../../styles/button";
import {Navbar} from "./Navbar";
import {ScrollToEnum, TabEnum} from "../../enum";
import {scrollTo} from "../../utils/scrollTo";

interface IHeaderProps {
    onTabChange(tab: TabEnum): void

    onPopupOpen(): void

    setIsMenuOpen(value: boolean): void

    isMenuOpen: boolean

    selectedTab: TabEnum
}

export const Header = ({onTabChange, selectedTab, onPopupOpen, setIsMenuOpen, isMenuOpen}: IHeaderProps) => {
    const Theme = useTheme();


    return (
        <div className={'header'}>
            <div className={'header-content animation2'}>
                <div className={'header-left'}>
                    <Logo onClick={() => {
                        onTabChange(TabEnum.main)
                        setTimeout(() => scrollTo(ScrollToEnum.intro), 200)
                    }}/>
                    <div className={'header-pc'}>
                        <Text18 color={selectedTab === TabEnum.main ? Theme.colors.indicator : Theme.colors.secondary}
                                hoverColor={Theme.colors.indicator}
                                pointer={true}
                                onClick={() => {
                                    onTabChange(TabEnum.main)
                                    setTimeout(() => scrollTo(ScrollToEnum.intro), 1)
                                }}
                        >
                            О продукте
                        </Text18>
                        <Text18
                            color={selectedTab === TabEnum.partners ? Theme.colors.indicator : Theme.colors.secondary}
                            hoverColor={Theme.colors.indicator}
                            pointer={true}
                            onClick={() => {
                                onTabChange(TabEnum.partners)
                                setTimeout(() => scrollTo(ScrollToEnum.partners), 1)
                            }}>
                            Партнерам
                        </Text18>
                    </div>
                </div>
                <div>
                    <div className={'header-pc'}>
                        <DefaultBtn onClick={onPopupOpen} style={{backgroundColor: Theme.colors.darkGrey}}>
                            <Text18 color={Theme.colors.secondary} style={{fontWeight: 500}}>
                                Связаться c нами
                            </Text18>
                        </DefaultBtn>
                    </div>
                    <div className={'header-mobile'}>
                        {!isMenuOpen && <Menu onClick={() => setIsMenuOpen(true)}/>}
                        {isMenuOpen && <Close onClick={() => setIsMenuOpen(false)}/>}
                    </div>
                </div>
            </div>
            {isMenuOpen && <Navbar selectedTab={selectedTab} onTabChange={tab => onTabChange(tab)}
                                   onClose={() => setIsMenuOpen(false)}/>}
        </div>
    )
}