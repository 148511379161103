import styled from "styled-components";
import {WindowSizePXEnum} from "../interface/window";

export const Content16 = styled.div`
  margin: 16px 0;
`;

export const ContentTop4 = styled.div`
  margin-top: 4px
`;

export const ContentTop8 = styled.div`
  margin-top: 8px
`;

export const ContentTop16 = styled.div`
  margin-top: 16px;
`;
export const ContentTop24 = styled.div`
  margin-top: 24px;
`;
export const ContentTop32 = styled.div`
  margin-top: 32px;
`;
export const ContentTop40 = styled.div`
  margin-top: 40px;
`;

export const Flex = styled.div`
  display: flex;
  height: 100%;
`;

export const FlexRow = styled(Flex)<{ gap?: string }>`
  flex-direction: row;
  gap: ${props => props.gap}
`
export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const NavbarBackground = styled.div`
  position: fixed;
  overflow: hidden;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.primary};
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding-top: 10px;

`
export const NavbarItem = styled.div`
  cursor: pointer;
  padding: 16px 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    padding: 16px;
  }
`

export const NavbarBtn = styled.div`
  position: fixed;
  bottom: 24px;
  left: 32px;
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    left: 16px;
  }
`