import React from 'react';
import {Text18lh28, Title48lh67} from "../../styles/fonts";
import {BenefitsCards, BenefitsContainer, BenefitsContent, BenefitsTitle} from "./style";
import {BenefitCard} from "./BenefitCard";
import {ActionControl, Guest, Integration, Requirements, Segmentation, Transparency} from "../../icons/icons";
import {ContentTop16} from "../../styles/content";


export const Benefits = () => {

    return (
        <BenefitsContainer className={'benefits'}>
            <BenefitsContent>
                <BenefitsTitle>
                    <Title48lh67> Преимущества
                        DECK AUTH</Title48lh67>
                    <Text18lh28 style={{textAlign: 'center'}}>
                        Возможность управлять безопасностью множества сетевых подключений
                        из единой консоли. Контроль подключений как для систем IoT, так и для персональных ПК и
                        мобильных устройств
                    </Text18lh28>
                </BenefitsTitle>
                <ContentTop16/>
                <BenefitsCards>
                    <BenefitCard title={'Прозрачность'}
                                 description={`Что, кто и когда подключается к сети. Журналирование попыток подключения
с целью использования этой информации в расследовании инцидентов ИБ`}
                                 icon={<Transparency/>}/>

                    <BenefitCard title={'Сегментация'}
                                 description={`Помещение хостов в определённые сетевые сегменты на основе бизнес-правил. Уменьшение зоны доверия при помощи автоматизации`}
                                 icon={<Segmentation/>}/>

                    <BenefitCard title={'Гостевой доступ'}
                                 description={`Идентификация пользователей беспроводных сетей согласно ФЗ и постановлений Правительства РФ`}
                                 icon={<Guest/>}/>

                    <BenefitCard title={'Контроль действий администраторов и операторов'}
                                 description={`Гранулярный контроль и аудит действий администраторов и операторов сетевых устройств и других ИТ-систем при помощи политик с использованием протокола TACACS+`}
                                 icon={<ActionControl/>}/>

                    <BenefitCard title={'Выполнение требований'}
                                 description={`Исполнение как внутренних, так и внешних регламентов и нормативных актов при организации подключений сотрудников и гостей к сети передачи данных`}
                                 icon={<Requirements/>}/>

                    <BenefitCard title={'Интеграции'}
                                 description={`Увеличение отдачи от инвестиций за счёт совместной работы различных средств безопасности и инфраструктуры c платформой контроля сетевого доступа`}
                                 icon={<Integration/>}/>

                    <BenefitCard title={''}
                                 description={``}
                                 invisible
                                 icon={<Integration/>}/>
                </BenefitsCards>
            </BenefitsContent>
        </BenefitsContainer>
    )
}
