import React, {useEffect, useRef, useState} from "react";
import useOnScreen from "../../hooks/useOnScreen";
import {ResourceText, ResourceTextMenu, ResourceTextMenuItem} from "../resources/style";
import {Text16, Text18lh28, TextLink, TextLinkDiv, Title24, Title48lh58, Title48lh67} from "../../styles/fonts";
import {
    PartnersBlock,
    PartnersCard,
    PartnersContainer,
    PartnersContainer2,
    PartnersContent,
    PartnersImage,
    PartnersRow,
    PartnersText,
} from "./style";
import {useTheme} from "styled-components";
import {useOnClickOutside} from "../../hooks/useClickOutside";
import {ContentTop32} from "../../styles/content";
import {Theme} from "../../styles/Theme";
import {DefaultBtn} from "../../styles/button";

const text = `В основе успешных внедрений DECK AUTH лежит тесная работа с нашими партнёрами, которые помимо поставки ПО обеспечивают полный комплекс услуг по внедрению и техническому сопровождению сетей, на которых активирован сервис по контролю за подключениями. Для партнёров, которые готовы разделить работы по внедрению DECK AUTH c нами мы предлагаем услуги профессионального сервиса.`

interface IPartnersProps {
    onPopupOpen(): void
}

export const Partners = ({onPopupOpen}: IPartnersProps) => {

    const ref = useRef(null)
    const theme = useTheme()
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    useEffect(() => {
        setIsVisible(visible)
        if (!visible) {
            setMenuOpened(false)
        }
    }, [visible])
    const menuRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(menuRef, () => setMenuOpened(false));

    return (
        <div ref={ref}>
            <PartnersContainer className={'partners'}>
                <PartnersContent>
                    <PartnersBlock>
                        <PartnersText color={theme.colors.secondary}>
                            <Title48lh58 className={isVisible ? 'animation5' : ''}
                                         style={{opacity: `${isVisible ? 1 : 0}`}}>
                                Создаём надёжные политики
                                для безопасных подключений
                                вместе с партнёрами
                            </Title48lh58>
                            <Text18lh28>
                                {text}
                            </Text18lh28>
                            <ContentTop32>
                                <DefaultBtn onClick={onPopupOpen}
                                            style={{backgroundColor: Theme.colors.indicator}}>
                                    <Text18lh28 color={Theme.colors.secondary}>Стать партнером</Text18lh28>
                                </DefaultBtn>
                            </ContentTop32>
                        </PartnersText>
                        <PartnersImage src={`/images/Partners.png`}/>
                    </PartnersBlock>

                </PartnersContent>
            </PartnersContainer>
            <PartnersContainer2>
                <PartnersContent>
                    <Title48lh67 className={isVisible ? 'animation5' : ''}
                                 style={{opacity: `${isVisible ? 1 : 0}`}}>Ресурсы для партнеров</Title48lh67>
                    <PartnersRow>
                        <PartnersCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Ответы на часто задаваемые вопросы</Title24>
                                <Text16>Функции, совместимость, лицензирование, заказ и приобретение, миграция с
                                    существующих систем (FAQ)</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"} href={'/files/DECK AUTH FAQ.pdf'}>Скачать
                                    файл</TextLink>
                            </ResourceText>
                        </PartnersCard>
                        <PartnersCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Правила заказа</Title24>
                                <Text16>Детальная информация о правилах заказа и подготовки спецификации (Ordering
                                    guide)</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"}
                                          href={'/files/DECK AUTH ordering guide.pdf'}>Скачать файл</TextLink>
                            </ResourceText>
                        </PartnersCard>
                        <PartnersCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Профессиональный сервис</Title24>
                                <Text16>Описание фиксированного набора услуг по внедрению системы (Fixed Service) для
                                    различных уровней лицензирования </Text16>
                                <div style={{position: 'relative'}}>
                                    <TextLinkDiv onClick={() => setMenuOpened(true)}>
                                        Скачать файл
                                    </TextLinkDiv>
                                    <ResourceTextMenu className={menuOpened ? 'visible' : 'hidden'} ref={menuRef}>
                                        <ResourceTextMenuItem>
                                            <TextLink rel={'noreferrer'} target={"_blank"}
                                                      href={'/files/DECK AUTH service guest.pdf'}>
                                                Уровень лицензирования «Гость»
                                            </TextLink>
                                        </ResourceTextMenuItem>
                                        <ResourceTextMenuItem>
                                            <TextLink rel={'noreferrer'} target={"_blank"}
                                                      href={'/files/DECK AUTH service enterprise.pdf'}>
                                                Уровень лицензирования «Предприятие»
                                            </TextLink>
                                        </ResourceTextMenuItem>
                                        <ResourceTextMenuItem>
                                            <TextLink rel={'noreferrer'} target={"_blank"}
                                                      href={'/files/DECK AUTH service premier.pdf'}>
                                                Уровень лицензирования «Премьер»
                                            </TextLink>
                                        </ResourceTextMenuItem>
                                    </ResourceTextMenu>
                                </div>

                            </ResourceText>
                        </PartnersCard>
                        <PartnersCard>
                            <ResourceText className={isVisible ? 'animation3' : ''}
                                          style={{opacity: `${isVisible ? 1 : 0}`}}>
                                <Title24>Опросник для подготовки спецификации</Title24>
                                <Text16>Чтобы подготовить спецификацию, используйте шаблон для сбора данных о подключениях, которые требуется контролировать</Text16>
                                <TextLink rel={'noreferrer'} target={"_blank"}
                                          href={'/files/DECK AUTH Опросник.xlsx'}>Скачать файл</TextLink>
                            </ResourceText>
                        </PartnersCard>
                    </PartnersRow>
                </PartnersContent>
            </PartnersContainer2>
        </div>
    )
}