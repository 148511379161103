import React, {useEffect, useRef, useState} from 'react';
import {Text18lh28, Title48} from "../../styles/fonts";
import {
    ArchitectureBlock,
    ArchitectureContainer,
    ArchitectureContent,
    ArchitectureText,
    ArchitectureTitle, CardBlueLight, FlexBox,
} from "./style";
import {ContentTop16} from "../../styles/content";
// @ts-ignore
import MyVideo from "./video.mp4";
import useOnScreen from "../../hooks/useOnScreen";

export const Architecture = () => {
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)

    useEffect
    (() => {
        setIsVisible(visible)
        const video = document.getElementById('ArchitectureVideo') as HTMLVideoElement
        if (video && (window.innerWidth > 1200)) {
            if (visible) {
                video.currentTime = 0
                video.play()
            }
        }
    }, [visible])

    return (
        <ArchitectureContainer className={'architecture'}>
            <ArchitectureContent ref={ref}>
                <ArchitectureTitle>
                    <Title48 className={isVisible ? 'animation5' : ''} style={{opacity: `${isVisible ? 1 : 0}`}}>
                        Архитектура
                    </Title48>
                </ArchitectureTitle>

                <ArchitectureBlock>
                    <div className={isVisible ? 'animation3' : ''}
                         style={{opacity: `${isVisible ? 1 : 0}`}}>
                        <video id={'ArchitectureVideo'} width="100%" height="100%" preload='auto' muted loop controls>
                            <source src={MyVideo} type="video/mp4"/>
                            Your browser does not support HTML5 video.
                        </video>
                    </div>

                    <ArchitectureText>
                        <FlexBox>
                            <CardBlueLight className={isVisible ? 'animation3' : ''}>
                                DECK AUTH обеспечивает контроль за проводными и беспроводными подключениями
                                пользователей и устройств, взаимодействуя с существующим сетевым оборудованием при
                                помощи протокола RADIUS.
                            </CardBlueLight>
                            <CardBlueLight className={isVisible ? 'animation3' : ''}>
                                Для сотрудников организации система обеспечивает контроль при помощи механизмов
                                протокола IEEE 802.1x, а гостевые подключения контролируются при помощи встроенного в
                                систему captive-портала. В качестве источников аутентификации могут быть использованы
                                как внешние службы каталога, так и внутренние.
                            </CardBlueLight>
                        </FlexBox>

                        <FlexBox>
                            <CardBlueLight className={isVisible ? 'animation3' : ''}>
                                Интеграция с экосистемой ИБ, например Межсетевыми Экранами или другой
                                IT-инфраструктурой, например сетевыми фабриками или публичными облаками, происходит при
                                помощи открытых API. Архитектура самого приложения DECK AUTH является микросервисной,
                                с возможностью горизонтального масштабирования.
                            </CardBlueLight>
                            <CardBlueLight className={isVisible ? 'animation3' : ''}>
                                Поддержка протокола TACACS+ позволяет реализовать аудит действий администраторов и
                                операторов оборудования используя ту же самую платформу, которая используется для
                                контроля за сетевыми подключениями пользователей и устройств.
                            </CardBlueLight>
                        </FlexBox>
                    </ArchitectureText>
                </ArchitectureBlock>

            </ArchitectureContent>
        </ArchitectureContainer>
    )
}
