import styled from "styled-components";
import {WindowSizePXEnum} from "../../interface/window";
import {ContentTop16} from "../../styles/content";
import {Text18lh28} from "../../styles/fonts";

export const ArchitectureContainer = styled.div`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #E1E1E1;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 42px 0;
  }
`
export const ArchitectureContent = styled.div`
  width: 1400px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: 100%;
  }
`
export const ArchitectureBlock = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: ${WindowSizePXEnum.s}) {
    margin-top: 32px;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    margin-top: 0;
  }
`

export const ArchitectureText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  
  ${ContentTop16} {
    width: 100%;
  }
  
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: unset;
    padding: 0 32px;
    flex-direction: column;

  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;
    padding: 0 16px;
    
  }
`
export const ArchitectureTitle = styled.div`
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: unset;
    padding: 0 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;
    padding: 0 16px;
  }
`

export const VideoTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
`

export const CardBlueLight = styled(Text18lh28)`
  background: #F6F9FB;
  padding: 26px;
`

export const FlexBox = styled(Text18lh28)`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;
`
