import styled from "styled-components";
import {FlexColumn} from "../../styles/content";
import {WindowSizePXEnum} from "../../interface/window";

export const IntroContainer = styled.div`
  height: 590px;
  background-color: ${({theme}) => theme.colors.primary};
  padding: 50px 100px 70px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    padding: 0;
    height: unset;
  }
`

export const IntroContent = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    max-width: unset;
    width: 100%;
    flex-direction: column;
    gap: 0;
  }
`
export const IntroLeftContent = styled(FlexColumn)`
  width: 595px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    width: unset;
    padding: 48px 95px 48px 32px;
  }
  @media screen and (max-width: ${WindowSizePXEnum.xs}) {
    width: unset;
    height: unset;
    padding: 24px 16px;
  }
`

export const IntroRightContent = styled.div`
  width: 764px;
  height: 457px;
  @media screen and (max-width: ${WindowSizePXEnum.md}) {
    height: unset;
    width: 100%;
    margin-bottom: -3px;
    border-bottom: 1px solid #E0E0E0;
  }
`
