

export const BASE_URL = "https://air-bot.deck.lc";

export interface IForm {
   form: {
       name: string
       phone: string
       company: string
       email: string
       message?: string
   }
}
//@ts-ignore
const handleResponse = (response) => {
    if (response && response.ok) {
        return response
    } else {
        Promise.reject(`Ошибка ${response.status}`)
    };
}
//@ts-ignore
const handleError = (response) => {
    console.log(response)
}

export function sendForm({form}: IForm) {
    return fetch(
        `${BASE_URL}/telegram-auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        }
    ).then(handleResponse).catch(handleError);
}