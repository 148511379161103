import {useEffect, useMemo, useState} from "react"

export default function useOnScreen(ref: React.MutableRefObject<null | Element>) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)
    ), [])

    useEffect(() => {
        if (ref.current !== null) observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect()
        }
    }, [observer, ref])

    return isIntersecting
}