import styled from "styled-components";

export const DefaultBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 229px;
  height: 52px;
  cursor: pointer;

  :hover {
    filter: brightness(85%);
  }

  :active {
    filter: brightness(90%);
  }
`

export const DisabledBtn = styled(DefaultBtn)`
  cursor: default;
  pointer-events: none;
  filter: opacity(.7);
`