import React, {useEffect, useRef, useState} from 'react'
import {CooperationContainer, CooperationContent, CooperationRow} from "./style";
import {Title48lh67} from "../../styles/fonts";
import {Form} from "./Form";
import {Contacts} from "./Contacts";
import {Theme} from "../../styles/Theme";
import useOnScreen from "../../hooks/useOnScreen";

export const Cooperation = () => {
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        setIsVisible(visible)
    }, [visible])

    return (
        <CooperationContainer className={'cooperation'}>
            <CooperationContent>
                <Title48lh67 color={Theme.colors.secondary}
                             ref={ref}
                             className={isVisible ? 'animation2' : ''}
                             style={{opacity: `${isVisible ? 1 : 0}`}}>Начать сотрудничество</Title48lh67>
                <CooperationRow>
                    <Form/>
                    <Contacts/>
                </CooperationRow>
            </CooperationContent>
        </CooperationContainer>
    )
}